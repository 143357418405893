import * as React from "react";

const faqs = [
  {
    id: 1,
    question: "Sygnalista - kto to jest?",
    answer:
      "Sygnalista jest osobą zgłaszającą w swoim miejscu pracy lub poza nim nieprawidłowości oraz naruszenia prawa Unii, mające miejsce w środowisku zawodowym lub w zakładzie pracy.",
  },
  {
    id: 2,
    question: "Dlaczego dyrektywa o ochronie sygnalistów dotyczy mojej firmy?",
    answer:
      "Dyrektywa Parlamentu Europejskiego i Rady w sprawie ochrony osób zgłaszających naruszenia prawa Unii zostaje zaimplementowana do polskiego prawa w grudniu 2021 roku. Do tego czasu przedsiębiorstwa muszą mieć wdrożone rozwiązania, zapewniające anonimowość sygnalistom.",
  },
  {
    id: 3,
    question: "Czy Whistleblower jest dla mnie?",
    answer:
      "Whistleblower może zostać wdrożony w każdej firmie. Zostaw nam swój adres e-mail, a nasz konsultant przedstawi Ci szczegóły.",
  },
  {
    id: 4,
    question:
      "Kto może przeszkolić moich pracowników z działania Whistleblower?",
    answer: "Zapewniamy kompleksowe szkolenie z obsługi narzędzia.",
  },
  {
    id: 5,
    question: "Czy Whistleblower spełnia wymagania dyrektywy?",
    answer:
      "Zdecydowanie tak. Whistleblower zapewni anonimowość sygnaliście, a Twoja firma zyska bezpieczny kanał komunikacji.",
  },
  {
    id: 6,
    question: "Kto może odbierać zgłoszenia z Whistleblower?",
    answer:
      "Wszystkie zgłoszenia od sygnalistów trafiają do wcześniej ustalonych osób w Twojej firmie.",
  },
];

export const Faq = () => {
  return (
    <div id="faq" className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900">
          O co w ogóle chodzi?
        </h2>
        <div className="mt-6 pt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
