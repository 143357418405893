import * as React from "react";

import Layout from "../layouts/Layout";

import { Header } from "../sections/Header";
import { Hero } from "../sections/Hero";
import { Faq } from "../sections/Faq";
import { Cta } from "../sections/Cta";
import { Features } from "../sections/Features";
import { About } from "../sections/About";
import { Offer } from "../sections/Offer";
import { Contact } from "../sections/Contact";
import { Footer } from "../sections/Footer";
import { AlertCookies } from "../sections/AlertCookies";

const Index = () => {
  return (
    <Layout>
      <Header />
      <main>
        <Hero />
        <Faq />
        <Cta />
        <Features />
        <About />
        <Offer />
        <Contact />
      </main>
      <Footer />

      <AlertCookies />
    </Layout>
  );
};

export default Index;
