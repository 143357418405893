import * as React from "react";

import stats from "../images/stats.jpg";

const metrics = [
  {
    id: 1,
    stat: "300+",
    text: "wdrożeń systemów zarządzania jakością i środowiskiem",
  },
  {
    id: 2,
    stat: "200+",
    text: "wdrożeń RODO w firmach prywatnych i jednostkach państwowych",
  },
  {
    id: 3,
    stat: "800+",
    text: "szkoleń w zakresie RODO, cyberbezpieczeństwa, systemów zarządzania jakością i środowiskiem, compliance",
  },
  {
    id: 4,
    stat: "30+",
    text: "jesteśmy Inspektorami Ochrony Danych w firmach prywatnych oraz instytucjach państwowych",
  },
];

export const About = () => {
  return (
    <div id="about" className="relative bg-gray-900">
      <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              className="h-full w-full object-cover opacity-10 xl:absolute xl:inset-0"
              src={stats}
              alt="Man in a suit"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold tracking-wide uppercase">
            <span className="text-blue-500 text-transparent">
              twórcy whistleblowera
            </span>
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">
            Fib.Code - kim jesteśmy
          </p>
          <p className="mt-5 text-lg text-gray-300">
            Nasz interdyscyplinarny zespół składa się z doświadczonych
            ekspertów-praktyków związanych z RODO, normami ISO,
            cyberbezpieczeństwem i compliance (audytorzy systemów ISO, radcy
            prawni, prawnicy) oraz specjalistów w dziedzinie IT (project
            managerowie, programiści, administratorzy sieci informatycznych).
            Dzięki temu szkolimy, doradzamy i projektujemy wspomagające
            aplikacje m.in. w dziedzinie bezpieczeństwa informacji i w obszarach
            kadrowych, ulepszając wewnętrzne i zewnętrzne funkcjonowanie każdej
            firmy, dostosowując do aktualnych wymogów prawa.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {metrics.map((item) => (
              <p key={item.id}>
                <span className="block text-2xl font-bold text-blue-500">
                  {item.stat}
                </span>
                <span className="mt-1 block text-base text-gray-300">
                  {item.text}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
