import * as React from "react";

import lawyer from "../images/lawyer.png";

export const Cta = () => {
  return (
    <div className="relative bg-gray-900">
      <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src={lawyer}
          alt="Notebook"
        />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
            Dostosuj się już dziś
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            Whistleblower - bezpieczny kanał komunikacji
          </p>
          <p className="mt-3 text-lg text-gray-300">
            Zapewnij swoim pracownikom anonimowość, wdrażając wewnętrzne
            procedury sygnalizowania.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base rounded-md text-white bg-blue-500 hover:bg-blue-600"
              >
                Skontaktuj się z doradcą
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
