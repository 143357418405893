import * as React from "react";
import { useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/solid";

import illustration from "../images/illustration.png";

export const Hero = () => {
  const [mailingSubmit, setMailingSubmit] = useState(false);

  const showNotification = () => {
    setMailingSubmit(true);
    setTimeout(() => {
      setMailingSubmit(false);
    }, 3000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData(e.target);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        showNotification();
        e.target.reset();
      })
      .catch((error) => alert(error));
  };

  return (
    <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl">
                <span className="block">Zapewnij bezpieczeństwo</span>
                <span className="pb-3 block bg-clip-text text-transparent bg-blue-500 sm:pb-5">
                  sygnaliście
                </span>
              </h1>
              <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                Whistleblower to bezpieczny kanał komunikacji udostępniony
                wewnątrz Twojej firmy, spełniający wszystkie wymagania ustawy o
                zgłaszaniu naruszeń.
              </p>
              <div className="mt-2">
                <div
                  className={`${
                    mailingSubmit ? "opacity-100" : "opacity-0"
                  } transition ease-in duration-150 rounded-md bg-green-100 p-4 mb-4`}
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-green-800">
                        Zapisano adres e-mail.
                      </p>
                    </div>
                  </div>
                </div>

                <form
                  name="mailing"
                  onSubmit={handleSubmit}
                  className="sm:max-w-xl sm:mx-auto lg:mx-0"
                  data-netlify
                >
                  <input type="hidden" name="form-name" value="mailing" />
                  <div className="sm:flex">
                    <div className="min-w-0 flex-1">
                      <label htmlFor="email" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email"
                        name="mailing"
                        type="email"
                        placeholder="Adres e-mail"
                        required
                        className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                      />
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <button
                        type="submit"
                        className="block w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                      >
                        Dowiedz się więcej
                      </button>
                    </div>
                  </div>
                  <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                    Pozostaw swój adres e-mail, jeżeli chcesz, aby nasi konsultanci przedstawili w jaki sposób możesz zapewnić sobie zgodność z ustawą. Dane osobowe przetwarzamy celem przedstawienia oferty i realizacji czynności przed zawarciem umowy. Użytkownik klikając „Dowiedz się więcej” wyraża zgodę na przetwarzanie danych w celach marketingu bezpośredniego. 
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
              <img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={illustration}
                alt="Communication between whistleblower"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
