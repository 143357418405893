import * as React from "react";

import audit from "../images/offer/audit.svg";
import analize from "../images/offer/analize.svg";
import compliance from "../images/offer/compliance.svg";
import docs from "../images/offer/docs.svg";
import learning from "../images/offer/learning.svg";
import whistleblower from "../images/offer/whistleblower.svg";

const offerElements = [
  {
    id: 1,
    icon: audit,
    description: "Audyt przedsiębiorstwa",
  },
  {
    id: 2,
    icon: whistleblower,
    description:
      "Wdrożenie systemu zgłaszania nieprawidłowości - Whistleblower",
  },
  {
    id: 3,
    icon: analize,
    description: "Identyfikacja, analiza ryzyka i ocena zgodności",
  },
  {
    id: 4,
    icon: compliance,
    description: "Outsourcing funkcji Compliance Officera",
  },
  {
    id: 5,
    icon: docs,
    description: "Przygotowanie pełnej dokumentacji wdrożeniowej",
  },
  {
    id: 6,
    icon: learning,
    description: "Szkolenia stacjonarne oraz e-learningowe",
  },
];

export const Offer = () => {
  return (
    <div id="faq" className="bg-white">
      <div className="max-w-6xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-12">
        <h4 className="text-sm font-semibold tracking-wide uppercase text-blue-500 text-center">
          Co składa się na naszą ofertę
        </h4>
        <p className="text-lg leading-6 font-semibold text-justify lg:mx-24 mt-5">
          Możesz skorzystać z oferowanego przez nas narzędzia lub dowolnie
          skomponować dostępne poniżej rozwiązania w postaci pakietu,
          najbardziej odpowiadającego potrzebom Twojego przedsiębiorstwa.
        </p>
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 mt-14">
          {offerElements.map((element) => (
            <div key={element.id} className="flex items-center">
              <img src={element.icon} alt={`Offer icon ${element.id}`} />
              <p className="mt-2 px-4 sm:text-xl leadning-7">
                {element.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
