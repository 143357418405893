import * as React from "react";
import { useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/solid";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

export const Contact = () => {
  const [contactSubmit, setContactSubmit] = useState(false);

  const showNotification = () => {
    setContactSubmit(true);
    setTimeout(() => {
      setContactSubmit(false);
    }, 3000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData(e.target);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        showNotification();
        e.target.reset();
      })
      .catch((error) => alert(error));
  };

  return (
    <>
      <div id="contact" className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-full bg-gray-50" />
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Kontakt
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Zaufaj profesjonalistom - skontaktuj się z nami i zapewnij
                bezpieczeństwo swojemu sygnaliście.
              </p>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>Rynek 29, 32-300 Olkusz</p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+48 32 494 48 24</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">info@whistleblower.pl</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                name="contact"
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-y-6"
                data-netlify
              >
                <input type="hidden" name="form-name" value="contact" />
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Imię i nazwisko
                  </label>
                  <input
                    type="text"
                    name="full-name"
                    id="full-name"
                    autoComplete="name"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Imię i nazwisko"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Email"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Telefon
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Telefon"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Wiadomość
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                    placeholder="Wiadomość"
                    defaultValue={""}
                    required
                  />
                </div>
                <div class="mt-3 text-lg leading-6 text-gray-500">
                Klikając przycisk „Wyślij”, wyrażasz dobrowolną i świadomą zgodę na przetwarzanie danych osobowych celem przedstawienia oferty oraz realizacji czynności przed zawarciem umowy. Zgoda dotyczy przetwarzania danych osobowych w celach marketingu bezpośredniego z wykorzystaniem urządzeń końcowych, jakimi są: adres elektroniczny e-mail oraz telefon.
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Wyślij
                  </button>
                </div>
              </form>

              <div
                className={`${
                  contactSubmit ? "opacity-100" : "opacity-0"
                } transition ease-in duration-150 rounded-md bg-green-100 p-4 my-4`}
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Pomyślnie wysłano wiadomość e-mail
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-full bg-gray-50" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <p className="w-full text-base text-gray-500 ">
            Administratorem danych osobowych umieszczonych w formularzu
            kontaktowym jest – Fib.Code Sp. z o.o. z siedzibą w Olkusz przy ul.
            Rynek 29, 32-300 Olkusz, zarejestrowana w rejestrze przedsiębiorców
            Krajowego Rejestru Sądowego, pod numerem KRS: 0000804872, NIP:
            637-220-84-21, REGON: 384412096. Dane osobowe będą przetwarzane w
            celu kontaktu zwrotnego, prawnie uzasadnionego interesu
            Administratora danych. Wszelkie pytania dotyczące danych osobowych
            oraz prośby o modyfikację i zmianę należy kierować na adres poczty
            e-mail: gdpr@fibcode.com. Pozostałe informacje na temat
            przetwarzania danych znajdują się w{" "}
            <a href="/privacy/" className="font-medium text-gray-700 underline">
              Polityce Prywatności
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};
