import * as React from "react";
import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";

import Feature1 from "../images/features/feature1.png";
import Feature2 from "../images/features/feature2.png";

export const Features = () => {
  return (
    <div id="features" className="relative pt-16 pb-32 overflow-hidden">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
      />
      <div>
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-800">
                  <SparklesIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Anonimowa komunikacja
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Podczas sygnalizowania nie są wymagane żadne dane, dlatego
                  sygnaliści w Twojej firmie zachowują całkowitą anonimowość.
                  Sygnalista może sprawdzić status zgłoszenia, za pomocą
                  specjalnego kodu wygenerowanego dla jego zgłoszenia.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={Feature2}
                alt="Main page of whistleblower"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-800">
                  <InboxIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Zarządzanie zgłoszeniami
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Whistleblower daje Ci możliwość zarządzania zgłoszeniami. Mimo
                  anonimowości sygnalisty możesz pozostać z nim w kontakcie i
                  jeżeli to konieczne, dopytać o szczegóły, a także podziękować
                  za zgłoszenie lub poinformować o dalszych działaniach ze
                  strony firmy.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={Feature1}
                alt="Report interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
